import React, { useRef } from 'react'
import { Parallax, ParallaxLayer, IParallax } from '@react-spring/parallax'

// Little helpers ...
const url = (name: string, wrap = false) =>
  `${wrap ? 'url(' : ''}/img/${name}${wrap ? ')' : ''}`

export default function App() {
  const parallax = useRef<IParallax>(null!)
  return (
    <div style={{ width: '100%', height: '100%', background: 'black' }}>
      <Parallax ref={parallax} pages={3}>
        {/* <ParallaxLayer offset={1} speed={1} style={{ backgroundColor: '#253237' }} />
        <ParallaxLayer offset={2} speed={1} style={{ backgroundColor: '#010212' }} /> */}
        <ParallaxLayer offset={1} speed={1} style={{ backgroundColor: 'black' }} />
        <ParallaxLayer offset={2} speed={1} style={{ backgroundColor: 'black' }} />

        <ParallaxLayer
          offset={0}
          speed={0}
          factor={3}
          style={{
            backgroundImage: url('stars.svg', true),
            backgroundSize: 'cover',
          }}
        />

        <ParallaxLayer offset={2.2} speed={-0.3} style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {/* <img src={url('satellite2')} style={{ width: '15%', marginLeft: '70%' }} /> */}
          {/* <div style={{ color:'white' }}>Lorem ipsum dolor sit amet,</div> */}
          
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0.8} style={{ opacity: 0.1 }}>
          {/* <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '55%' }} /> */}
          {/* <img src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '15%' }} /> */}
        </ParallaxLayer>

        <ParallaxLayer offset={1.75} speed={0.5} style={{ opacity: 0.5 }}>
          {/* <img src={url('whatsapp-illustration4.png')} style={{ display: 'block', width: '20%', marginLeft: '5%' }} /> */}
          {/* <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '40%' }} /> */}
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0.2} style={{ opacity: 0.5 }}>
          <img src={url('whatsapp-illustration2.png')} style={{ display: 'block', width: '10%', marginLeft: '10%' }} />
          {/* <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '75%' }} /> */}
        </ParallaxLayer>

        <ParallaxLayer offset={1.6} speed={-0.1} style={{ opacity: 0.5 }}>
          <img src={url('whatsapp-illustration1.png')} style={{ display: 'block', width: '15%', marginLeft: '75%' }} />
          {/* <img src={url('cloud')} style={{ display: 'block', width: '25%', marginLeft: '30%' }} /> */}
          {/* <img src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '80%' }} /> */}
        </ParallaxLayer>

        <ParallaxLayer offset={2.6} speed={0.4} style={{ opacity: 0.6 }}>
          {/* <img style={{ display: 'block', width: '20%', marginLeft: '5%' }} /> */}
          {/* <img src={url('cloud')} style={{ display: 'block', width: '15%', marginLeft: '75%' }} /> */}
        </ParallaxLayer>

        <ParallaxLayer
          offset={2.5}
          speed={-0.4}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
          }}>
          {/* <img src={url('earth')} style={{ width: '0%' }} /> */}
          <div></div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={-0.3}
          style={{
            backgroundSize: '0%',
            backgroundPosition: 'center',
            backgroundImage: url('clients.svg', true),
          }}
        />

        <ParallaxLayer
          offset={0}
          speed={0.1}
          // onClick={() => parallax.current.scrollTo(1)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {/* <img src={url('server')} style={{ width: '50%' }} /> */}
          {/* <div style={{ fontSize: '6vw', color: 'white', textAlign: 'justify', fontFamily:'AtlantisInternational'}}> */}
          <div className="responsive-text">
          {/* In today's fast-paced business world, staying ahead is more challenging than ever. 
            Managing tasks, engaging clients, and streamlining workflows can feel like an uphill battle. */}
            El concepto de <span style={{ color: '#89ff89' }}>I</span>ntelligent <span style={{ color: '#89ff89' }}>A</span>utomation es aplicar la inteligencia artificial en tus procesos de negocio para lograr potenciar tus capacidades hacia otro nivel.
          </div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1}
          speed={0.1}
          // onClick={() => parallax.current.scrollTo(2)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {/* <img src={url('bash')} style={{ width: '40%' }} /> */}
          <div className="responsive-text">
          Los <span style={{color: '#89FF89'}}>a</span>gentes de inteligencia artificial habilitados para tu organización hacen esto realidad al automatizar procesos costosos como <span style={{color:'#FFEB89'}}>c</span>old calling, <span style={{color:'#FFEB89'}}>p</span>rospect qualifying, <span style={{color:'#FFEB89'}}>c</span>ustomer support. 
          </div>
        </ParallaxLayer>
        
        <ParallaxLayer
          offset={2}
          speed={1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
            <div className="responsive-text" style={{textAlign: 'center'}}>¿Estás listo para que la IA potencie tu negocio?</div>
          <img className="responsive-logo" src={"/img/AgenticBannerGreen.png"} />
          <a href="https://app.agentic.mx" style={{textAlign: 'center'}} className="responsive-text">
            Suscribete y conoce a nuestro agente
          </a>
        </ParallaxLayer>
        
      </Parallax>
    </div>
  )
}
